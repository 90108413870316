<template>
  <div id="page-user-view" class="px-4">
    <div class="flex pb-4 justify-between items-center border-0 border-b border-solid border-grey-light">
      <div class="flex items-center gap-2">
        <p class="font-medium sm:text-3xl text-2xl text-black">{{$t('ShipBlu Shield')}}<sup class="text-lg">{{'TM'}}</sup></p>
        <p class="text-2xl align-baseline">{{ $t('Ship with confidence, we\'ve got you covered') }}</p>
      </div>
      <vs-button v-if="merchantStatus === 'active' && merchantInsurance.insurance_package.name !== '' && merchantInsurance.insurance_package.id !== 1" class="bg-danger sm:px-7 px-5 sm:text-base text-sm font-semibold text-white cursor-pointer" @click="showDisableModal = true">{{$t('Unsubscribe')}}</vs-button>
    </div>

    <shipblu-card :cardLoading="insurancePackages.length === 0" class="mt-5 sm:p-6 p-4 border border-solid border-grey-light shadow-none">
      <p class="text-black sm:text-2xl text-xl mb-3 leading-tight">{{$t('Coverage Plans')}}</p>
      <p class="sm:text-lg">{{$t('While your business is safe with ShipBlu, accidents can happen. In case of loss or damage, your default compensation upon eligibility (please review T&Cs) is 500 EGP per order or the COD value, whichever is lower. For added peace of mind, you can subscribe to one of')}} <span class="font-bold">{{ $t('ShipBlu Shield™’s') }}</span> {{ $t(' plans for maximized coverage.') }}</p>
      <div class="md:mt-10 mt-6 grid lg:grid-cols-2 grid-cols-1 justify-items-center md:gap-6 gap-4">
        <div :class="`${merchantInsurance.insurance_package.id === insurance.id ? 'border-2 border-primary' : 'border border-grey-light'} border-solid rounded-lg w-full h-full sm:p-7 p-4`" v-for="(insurance, index) in insurancePackages" :key="index" >
          <label :for="`${index}`" :id="`${index}`" class="flex flex-col justify-between h-full">
            <div>
              <div class="flex-col justify-items-center relative">
                <p class="text-primary sm:text-2xl text-lg font-bold uppercase">{{insurance.name === 'Basic' ? $t('Blu Plan') : insurance.name === 'Premium' ? $t('Gold Plan') : ''}}</p>
                <span v-if="merchantInsurance.insurance_package.id === insurance.id" class="material-symbols-rounded text-4xl text-primary absolute right-0 top-0">check</span>
                <p class="mt-3 font-semibold sm:text-5xl text-4xl text-black">{{insurance.payout_coverage}}%</p>
              </div>
              <div class="sm:mt-10 mt-5 sm:text-lg text-sm" style="color: #737373;">
                <div class="mb-3">
                  <span>{{$t('Payout Coverage Up To') + ' '}}</span>
                  <span>{{ Math.abs(insurance.value_limit) > 999 ? Math.sign(insurance.value_limit) * ((Math.abs(insurance.value_limit)/1000).toFixed(1)) + ',000' : Math.sign(insurance.value_limit) * Math.abs(insurance.value_limit) }} {{ $t('EGP') }}</span>
                </div>
                <div class="mb-3">
                  <span>{{insurance.insurance_fees}}%</span>
                  <span>{{' ' + $t('Insurance Fees Per Order')}}</span>
                </div>
                <div v-if="merchantInsurance.insurance_package.id === insurance.id" class="flex justify-between gap-2">
                  <p>
                    <span>{{ $t('Default Declared Value is') + ' ' }}</span>
                    <span class="border-0 border-b border-solid">{{ (merchant.default_order_declared_value).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                    <span>{{ ' ' + $t('EGP') }}</span>
                  </p>
                  <p class="text-primary cursor-pointer" @click="openModal(insurance)">{{$t('Change')}}</p>
                </div>
              </div>
            </div>
            <div class="sm:mt-10 mt-6">
              <div v-if="merchant.is_self_signup  && merchantStatus === 'active'">
                <vx-tooltip position="top" color="warning" :text="`${$t('Your merchant profile is incomplete or still under review. You can still drop off your packages today!')}`">
                  <vs-button class="font-semibold sm:p-4 p-3 sm:text-xl w-full rounded-md leading-tight text-sm" disabled="true">{{$t('Subscribe')}}</vs-button>
                </vx-tooltip>
              </div>
              <div v-else>
                <vs-button color="#E5E5E5" v-if="merchantInsurance.insurance_package.id === insurance.id" class="font-semibold sm:p-4 p-3 sm:text-xl w-full rounded-md leading-tight text-sm cursor-auto" style="color: #737373;">{{$t('Current Plan')}}</vs-button>
                <vs-button v-else-if="merchantStatus === 'active'" class="font-semibold sm:p-4 p-3 sm:text-xl cursor-pointer w-full rounded-md leading-tight text-sm" @click="openModal(insurance)">{{ insuranceStatus(insurance) }}</vs-button>
              </div>
            </div>
          </label>
        </div>
      </div>
    </shipblu-card>
    
    <shipblu-card class="sm:p-6 p-4 mt-5 border border-solid border-grey-light shadow-none" :cardLoading="merchantInsuranceLoader">
      <div class="flex flex-start gap-4">
        <img class="w-6 h-6" src="@/assets/images/pages/light-2.svg" alt="">
        <div class="sm:text-lg flex flex-col gap-6">
          <p>
            <span class="font-bold text-black">{{$t('Payout Coverage')}}</span>
            <span>{{' ' + $t('compensation amount per eligible order according to declared value or COD per order and to the ShipBlu Shield plan you subscribed to, subject to the program Terms and Conditions.')}}</span>
          </p>
          <p>
            <span class="font-bold text-black">{{$t('Coverage Fees')}}</span>
            <span>{{' ' + $t('are calculated as a percentage of the COD or Declared value, based on the plan you subscribe to.')}}</span>
          </p>
          <p>
            <span class="font-bold text-black">{{$t('Default Declared Value')}}</span>
            <span class="font-normal">{{' ' +$t('can be used instead of entering a declared value for each individual order. Please note that adding a COD amount or declared value on any individual order overrides the default declared value.')}}</span>
          </p>
        </div>
      </div>
    </shipblu-card>

    <shipblu-card :cardLoading="merchantInsuranceHistoryLoader" v-if="merchantInsuranceHistoryLoader || (!merchantInsuranceHistoryLoader && merchantInsuranceHistory.length > 0)" class="mt-5 sm:p-6 p-4 border border-solid border-grey-light shadow-none">
      <p class="text-black sm:text-2xl text-xl mb-8">{{$t('Coverage History')}}</p>
      <div class="timeline flex flex-col gap-3 border-0 border-l-2 border-solid ml-6 pl-6">
        <div v-for="(item, index) in merchantInsuranceHistory.slice(0, limit)" :key="index" class="relative">
          <div class="icon absolute bg-white h-9 flex items-center justify-center">
            <div class="border border-solid border-grey rounded-full w-6 h-6 flex items-center justify-center">
              <div class="h-2 w-2 rounded-full flex-shrink-0" style="background-color: #737373;"></div>
            </div>
          </div>
          <div class="flex sm:flex-row flex-col sm:items-center sm:gap-4 gap-2 justify-between border border-grey-light border-solid py-4 sm:px-4 px-2 rounded-xl">
            <div>
              <div class="sm:text-base text-sm text-black">
                <div v-if="item.insurance_package.id === 1">
                  <span v-if="$i18n.locale === 'ar'">{{ $t('subscription is cancelled') + ' ' }}</span>
                  <span class="text-primary font-bold">{{ merchantInsuranceHistory.length > 0 ? merchantInsuranceHistory[index + 1].insurance_package.name === 'Basic' ? $t('Blu Plan') : merchantInsuranceHistory[index + 1].insurance_package.name === 'Premium' ? $t('Gold Plan') : '' : '' }}</span> 
                  <span v-if="$i18n.locale === 'en'">{{ ' ' + $t('subscription is cancelled') }}</span>
                </div>
                <div v-else>
                  <span>{{$t("Subscription changed to") + ' '}}</span>
                  <span class="text-primary font-bold">{{item.insurance_package ? item.insurance_package.name === 'Basic' ? $t('Blu Plan') : item.insurance_package.name === 'Premium' ? $t('Gold Plan') : '' : '' }}</span>
                </div>
              </div>
              <div class="text-sm mt-1">
                <span>{{ $t('Effective') + ': '}}</span>
                <span>{{ item.effective_date ? item.effective_date : '' }}</span>
              </div>
            </div>
            <p class="sm:text-base text-sm text-black ml-auto">{{ new Date(item.created).toLocaleDateString('fr-CA')}}</p>
          </div>
        </div>
      </div>
      <div class="mt-10 flex justify-end">
        <p class="cursor-pointer sm:text-base text-sm text-black border-0 border-b border-solid" @click="showMore()">{{ limit === 2 ? $t('See More History') : $t('See Less History')}}</p>
      </div>
    </shipblu-card>
    
    <shipblu-prompt
      class="shipment-modal shield-modal"
      @close="closeModal"
      @cancel="closeModal"
      :active.sync="showModal"
      :title="merchantInsurance.insurance_package.id === insurancePackage.id ? $t('Update Default Declared Value') : $t('Confirmation')"
      :buttons-hidden="true" autocomplete="nofill">
      <div class="relative">
        <p class="absolute z-10 text-sm leading-none bg-white px-1" :style="`${$i18n.locale === 'en' ? 'left: 8px;': 'right: 8px;'} top: -6px;`">{{ $t('Default Declared Value') }}</p>
        <vs-input class="w-full" v-model="declaredValue" type="text" name="declared value" v-validate="'required|numeric'" />
        <span class="text-danger text-sm" v-show="errors.has('declared value')">{{ $t(errors.first('declared value')) }}</span>
        <p class="text-lg absolute z-10 top-0 right-0 leading-tight p-4">{{ $t('EGP') }}</p>
      </div>
      <div v-if="merchantInsurance.insurance_package.id !== insurancePackage.id" class="flex mt-6">
        <div class="relative w-5 h-5">
          <input v-model="insurancePackage.termsCheck" class="w-5 h-5" type="checkbox" name="terms check" :checked="isSelected" v-validate="'required'"/>
        </div>
        <div class="flex ml-2 gap-1">
          <p class="text-sm">{{$t('I agree to the')}}</p>
          <a :href="$i18n.locale === 'en' ? 'https://support.shipblu.com/en/support/solutions/articles/154000209749-shipblu-shield-packages' : 'https://support.shipblu.com/ar/support/solutions/articles/154000209749-%D8%A8%D8%A7%D9%82%D8%A7%D8%AA-%D8%B4%D9%8A%D8%A8-%D8%A8%D9%84%D9%88-%D8%B4%D9%8A%D9%84%D8%AF'" target="_blank">
            <p :class="`${errors.first('terms check') ? 'text-danger' : 'text-primary'} underline text-sm`">{{$t('Terms and Conditions')}}</p>
          </a>
        </div>  
      </div>
      <button @click="merchantInsurance.insurance_package.id === insurancePackage.id ? updateMerchant() : addInsurance()" class="active-btn btn w-full mt-8 cursor-pointer h-12 font-semibold">{{ merchantInsurance.insurance_package.id === insurancePackage.id ? $t('Update') : $t('Confirm Plan') }}</button>
    </shipblu-prompt>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      @cancel="closeModal"
      :active.sync="showDisableModal"
      :title="$t('Are you Sure That You Want To Disable This Insurance Plan?')"
      :buttons-hidden="true" autocomplete="nofill">
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="showDisableModal = false" class="btn disable-btn cursor-pointer">{{ $t('Cancel') }}</button>
        <button @click="addInsurance('empty')" class="active-btn btn cursor-pointer">{{ $t('Confirm') }}</button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import { sendRequest }  from '../../http/axios/requestHelper'
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import common from '../../assets/utils/common'

export default {
  components: { ShipbluPrompt, ShipbluCard },
  data () {
    return {
      limit: 2,
      userMerchant: {},
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION,
      trackEventsDic: {
        1 : 'ShipBlu_SHIELD_DISABLED',
        2 : 'ShipBlu_SHIELD_SUBMITTED_BASIC',
        3 : 'ShipBlu_SHIELD_SUBMITTED_STANDARD',
        4 : 'ShipBlu_SHIELD_SUBMITTED_PREMIUM'
      },
      insurancePackages: [],
      insurancePackage: {},
      isSelected: false,
      merchant: {},
      merchantInsurance: {
        insurance_package: {}
      },
      showModal: false,
      showDisableModal: false,
      merchantInsuranceHistory: [],
      merchantInsuranceLoader: false,
      merchantInsuranceHistoryLoader: false,
      merchantStatus: '',
      declaredValue: ''
    }
  },
  methods: {
    insuranceStatus (insurance) {
      if (this.merchantInsurance.insurance_package.id === 1) {
        return this.$t('Subscribe')
      } else if (insurance.id < this.merchantInsurance.insurance_package.id) {
        return  this.$t('Downgrade')
      } else if (this.merchantInsurance.insurance_package.id < insurance.id) {
        return this.$t('Upgrade')
      }
    },
    showMore () {
      if (this.limit === 2) {
        this.limit = -1
      } else {
        this.limit = 2
      }
      this.MerchantInsuranceHistory()
    },
    closeModal () {
      this.showModal = false
      this.showDisableModal = false
    },
    loadInsurancePlans () {
      sendRequest(true, false, this, 'api/v1/pricing/insurance-packages/', 'get', null, true,
        (response) => { 
          response.data.forEach(item => {
            if (item.id !== 1 && item.id !== 3) {
              this.insurancePackages.push(item)
            }
          })
        }
      )
    },
    loadMerchantInsurance () {
      this.merchantInsuranceLoader = true
      sendRequest(true, false, this, `api/v1/merchants/${this.merchant.id}/insurance-packages/`, 'get', null, true,
        (response) => {
          this.merchantInsurance = response.data
          this.merchantInsuranceLoader = false
        }
      )
    },
    MerchantInsuranceHistory () {
      this.merchantInsuranceHistoryLoader = true
      sendRequest(true, false, this, `api/v1/tracking/merchants/${this.merchant.id}/insurance-packages/`, 'get', null, true,
        (response) => {
          this.merchantInsuranceHistory = response.data.results
          this.merchantInsuranceHistoryLoader = false
        }
      )

    },
    addInsurance (type) {
      this.$validator.validateAll().then(result => {
        if (result) {
          sendRequest(false, false, this, `api/v1/merchants/${this.merchant.id}/insurance-packages/`, 'post', {insurance_package: type === 'empty' ? 1 : this.insurancePackage.id}, true,
            () => {
              if (this.releaseVersion !== 'staging') {
                this.$mixpanel.track(`${this.insurancePackage.id ? this.trackEventsDic[this.insurancePackage.id] : this.trackEventsDic[1]}`, {
                  merchant_name: this.merchant.name,
                  merchant_email: this.userMerchant.email,
                  source: /(android)/i.test(navigator.userAgent)
                })
              }
              this.updateMerchant()
              this.insurancePackage = {}
              this.showModal = false
              this.showDisableModal = false
              this.insurancePackage.termsCheck = false
              this.MerchantInsuranceHistory()
            }
          )
        }
      })
    },
    updateMerchant () {
      this.$validator.validateAll().then(result => {
        if (result) {
          sendRequest(false, false, this, `api/v1/merchants/${this.merchant.id}/`, 'patch', {default_order_declared_value: Number(this.declaredValue)}, true,
            (response) => {
              this.showModal = false
              this.merchant = response.data
              this.insurancePackage.termsCheck = false
              localStorage.removeItem('merchant')
              common.checkMerchantInLocalStorage(this)
              this.loadMerchantInsurance()
            }
          )
        }
      })
    },
    openModal (item) {
      this.showModal = true
      this.declaredValue = this.merchant.default_order_declared_value
      this.insurancePackage.termsCheck = false
      this.insurancePackage = item
    }
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.userMerchant = results.userMerchantData
      this.merchant = results.merchantData
      this.merchantStatus = this.merchant.status
      this.declaredValue = this.merchant.default_order_declared_value
      this.loadInsurancePlans()
      this.loadMerchantInsurance()
      this.MerchantInsuranceHistory()
    })
  }
}
</script>

<style lang="scss">
.timeline {
  border-color: #737373;
  .icon {
    left: -32.5px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.vs-input--input {
  height: 48px;
}
.shield-modal .modal {
  width: 500px;
}
</style>
